import axios from 'axios';
/**
 * Configuration générale de l'API WordPress
 */
export const API_CONFIG = {
  // Timeouts et TTL
  TIMEOUTS: {
    REQUEST: 8000,      // 8 secondes pour les requêtes
    DEFAULT_TTL: 300000, // 5 minutes pour le cache standard
    LONG_TTL: 900000,   // 15 minutes pour le cache long
    SHORT_TTL: 60000    // 1 minute pour le cache court
  },

  // Configuration des endpoints
  ENDPOINTS: {
    WORDPRESS: '/wp-json/wp/v2',
    OPTIONS: '/wp-json/acf/v3/options',
    CUSTOM: '/wp-json/custom/v1',
    MEDIA: '/wp-json/wp/v2/media'
  },

  // Paramètres de pagination
  PAGINATION: {
    DEFAULT_PER_PAGE: 100,
    MAX_PER_PAGE: 100,
    MIN_PER_PAGE: 10
  },

  // Champs par défaut pour les requêtes
  FIELDS: {
    DEFAULT: 'id,title,slug,content,acf',
    MINIMAL: 'id,title,slug',
    FULL: 'id,title,slug,content,acf,excerpt,date,modified,status,author',
    MEDIA: 'id,title,source_url,alt_text,media_details'
  },

  // Configuration du cache
  CACHE: {
    MAX_MEMORY_ITEMS: 1000,
    MEMORY_CLEANUP_FACTOR: 0.2, // Nettoyer 20% quand plein
    TYPES: {
      MEDIA: 'media',
      ARTISTE: 'artiste',
      EVENT: 'event',
      GENERAL: 'general',
      FOOD: 'food',
      MERCH: 'merchandising',
      PARTNER: 'partenaire'
    }
  },

  // Configuration des médias
  MEDIA: {
    ALLOWED_TYPES: ['image/jpeg', 'image/png', 'image/webp', 'image/gif'],
    MAX_FILE_SIZE: 5242880, // 5MB
    IMAGE_SIZES: {
      THUMBNAIL: 'thumbnail',
      MEDIUM: 'medium',
      LARGE: 'large',
      FULL: 'full'
    }
  },

  // Messages d'erreur
  ERRORS: {
    NETWORK: {
      TIMEOUT: 'La requête a expiré',
      NOT_FOUND: 'Ressource non trouvée',
      SERVER_ERROR: 'Erreur serveur',
      UNAUTHORIZED: 'Non autorisé',
      FORBIDDEN: 'Accès refusé'
    },
    DATA: {
      INVALID: 'Données invalides',
      MISSING: 'Données manquantes',
      MALFORMED: 'Format de données incorrect'
    },
    CACHE: {
      SET_ERROR: 'Erreur lors de la mise en cache',
      GET_ERROR: 'Erreur lors de la récupération du cache'
    }
  },

  // Configuration des statuts
  STATUS: {
    PUBLISHED: 'publish',
    DRAFT: 'draft',
    PRIVATE: 'private',
    PENDING: 'pending'
  },

  // Paramètres des requêtes
  REQUEST: {
    RETRIES: 3,
    RETRY_DELAY: 1000,
    HEADERS: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  },

  // Configuration du environnements
  ENV: {
    DEVELOPMENT: 'development',
    PRODUCTION: 'production',
    STAGING: 'staging'
  }
};

/**
 * Création d'une instance axios avec la configuration par défaut
 */
export const createAxiosInstance = (baseURL) => {
  return axios.create({
    baseURL,
    timeout: API_CONFIG.TIMEOUTS.REQUEST,
    headers: API_CONFIG.REQUEST.HEADERS,
    validateStatus: status => status >= 200 && status < 300
  });
};

/**
 * Traitement des URLs d'images
 */
export const processImageUrl = (baseURL, url) => {
  if (!url) return null;
  if (url.startsWith('http')) return url;
  return `${baseURL}/wp-content/uploads/${url}`;
};

/**
 * Utilitaires de validation
 */
export const validateConfig = {
  isValidId: (id) => typeof id === 'number' && id > 0,
  isValidSlug: (slug) => typeof slug === 'string' && slug.length > 0,
  isValidUrl: (url) => {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  },
  isValidImageType: (type) => API_CONFIG.MEDIA.ALLOWED_TYPES.includes(type),
  isValidStatus: (status) => Object.values(API_CONFIG.STATUS).includes(status)
};

/**
 * Utilitaires de formatage
 */
export const formatUtils = {
  cleanSlug: (slug) => slug.toLowerCase().replace(/[^a-z0-9-]/g, '-'),
  formatDate: (date) => new Date(date).toISOString(),
  truncateString: (str, length = 100) => 
    str.length > length ? `${str.substring(0, length)}...` : str,
  stripHtml: (html) => html.replace(/<[^>]*>/g, '')
};

/**
 * Configuration de l'environnement
 */
export const getEnvironmentConfig = () => {
  const env = process.env.NODE_ENV || 'development';
  
  const configs = {
    development: {
      baseURL: 'https://www.poneyclubtoulouse.fr/backend',
      debug: true,
      cache: true
    },
    production: {
      baseURL: 'https://www.poneyclubtoulouse.fr/backend',
      debug: false,
      cache: true
    },
    staging: {
      baseURL: 'https://staging.poneyclubtoulouse.fr/backend',
      debug: true,
      cache: true
    }
  };

  return configs[env] || configs.development;
};

// Export de la configuration de l'environnement actuel
export const ENV_CONFIG = getEnvironmentConfig();

// Export d'une fonction pour créer une URL complète
export const createFullUrl = (path) => {
  const baseUrl = ENV_CONFIG.baseURL.replace(/\/$/, '');
  const cleanPath = path.replace(/^\//, '');
  return `${baseUrl}/${cleanPath}`;
};

// Export d'un helper pour la gestion des erreurs
export const handleApiError = (error) => {
  if (error.response) {
    const status = error.response.status;
    
    if (status === 404) return new Error(API_CONFIG.ERRORS.NETWORK.NOT_FOUND);
    if (status === 401) return new Error(API_CONFIG.ERRORS.NETWORK.UNAUTHORIZED);
    if (status === 403) return new Error(API_CONFIG.ERRORS.NETWORK.FORBIDDEN);
    if (status >= 500) return new Error(API_CONFIG.ERRORS.NETWORK.SERVER_ERROR);
  }
  
  if (error.code === 'ECONNABORTED') {
    return new Error(API_CONFIG.ERRORS.NETWORK.TIMEOUT);
  }
  
  return error;
};

export default {
  API_CONFIG,
  ENV_CONFIG,
  createAxiosInstance,
  processImageUrl,
  validateConfig,
  formatUtils,
  createFullUrl,
  handleApiError
};