export const ANIMATION_DURATION = 0.5;
export const STAGGER_DURATION = 0.1;
export const ANIMATION_EASE = 'easeInOut';

export const pageVariants = {
    initial: { opacity: 0},
    in: { opacity: 1 },
    out: { opacity: 0 },
  };

export const pageTransition = {
  type: 'tween',
  ease: ANIMATION_EASE,
  duration: ANIMATION_DURATION,
};

export const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { 
      staggerChildren: STAGGER_DURATION,
      delayChildren: ANIMATION_DURATION / 2,
    },
  },
};

export const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};