import React, { useState, useEffect, useCallback, lazy, Suspense } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AnimatePresence } from 'framer-motion';
import Header from './components/Header';
import FloatingAudioButton from './components/Audio/FloatingAudioButton';
import ScrollToTop from './components/ScrollToTop';
import Footer from './components/Footer';
import SEO from './components/SEO';
import wordpressAPI from './api/wordpress';
import { ROUTES_CONFIG } from './utils/routes';
import './App.scss';
import PageTransition from './components/PageTransition';
import Loader from './components/Loader';

const Home = lazy(() => import('./pages/Home'));
const Login = lazy(() => import('./components/Login'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      retry: 3,
      refetchOnWindowFocus: false,
      suspense: false,
    },
  },
});

function AppContent() {
  const location = useLocation();
  const [authState, setAuthState] = useState({
    isAuthenticated: localStorage.getItem('isAuthenticated') === 'true',
    isAudioEnabled: false,
    isPlayingSoundCloud: false,
    isVoluntarilyActivated: false
  });
  const [isLoading, setIsLoading] = useState(true);

  const { data: seoOptions, isLoading: loadingSEO } = useQuery(
    'seoOptions',
    () => wordpressAPI.getSiteOptions(),
    { staleTime: Infinity }
  );

  const handleLogin = useCallback((authenticated) => {
    setAuthState(prev => ({ ...prev, isAuthenticated: authenticated }));
    localStorage.setItem('isAuthenticated', authenticated.toString());
  }, []);

  const handleAudioChange = useCallback((updates) => {
    setAuthState(prev => ({ ...prev, ...updates }));
  }, []);

  useEffect(() => {
    if (!location.pathname.startsWith('/agenda/') && authState.isVoluntarilyActivated) {
      handleAudioChange({ isAudioEnabled: true });
    }
  }, [location.pathname, authState.isVoluntarilyActivated]);

  useEffect(() => {
    const currentRouteIndex = ROUTES_CONFIG.findIndex(route => route.path === location.pathname);
    if (currentRouteIndex !== -1) {
      const adjacentRoutes = [
        ROUTES_CONFIG[currentRouteIndex - 1],
        ROUTES_CONFIG[currentRouteIndex + 1]
      ].filter(Boolean);
      adjacentRoutes.forEach(route => {
        route.component?.preload?.();
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!loadingSEO && authState.isAuthenticated) {
      setIsLoading(false);
    }
  }, [loadingSEO, authState.isAuthenticated]);

  if (isLoading) {
    return <Loader />;
  }

  if (!authState.isAuthenticated) {
    return (
      <Suspense fallback={<Loader />}>
        <Login onLogin={handleLogin} />
      </Suspense>
    );
  }

  return (
    <>
      <SEO
        title="Accueil"
        description="Club de musique live à Toulouse - Concerts, événements et soirées"
        image={seoOptions?.seo?.image_seo}
      />
      <ScrollToTop />
      <FloatingAudioButton
        isAudioEnabled={authState.isAudioEnabled}
        onAudioStatusChange={(status) => handleAudioChange({ isAudioEnabled: status })}
        onVoluntaryActivation={(status) => handleAudioChange({ isVoluntarilyActivated: status })}
      />
      <Header />
      <main className="app-container overflow-x-hidden">
        <AnimatePresence mode="sync">
          <Suspense fallback={<Loader />}>
            <Routes location={location} key={location.pathname}>
              <Route
                path="/"
                element={
                  <PageTransition>
                    <SEO
                      title="Accueil"
                      description="Club de musique live à Toulouse - Concerts, événements et soirées"
                      image={seoOptions?.seo?.image_seo}
                    />
                    <Home />
                  </PageTransition>
                }
              />
              {ROUTES_CONFIG.map(({ path, component: Component }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <PageTransition>
                      <Component
                        isAudioEnabled={authState.isAudioEnabled}
                        isVoluntarilyActivated={authState.isVoluntarilyActivated}
                        setIsPlayingSoundCloud={(status) => handleAudioChange({ isPlayingSoundCloud: status })}
                      />
                    </PageTransition>
                  }
                />
              ))}
            </Routes>
          </Suspense>
        </AnimatePresence>
      </main>
      <Footer />
    </>
  );
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppContent />
      {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}
    </QueryClientProvider>
  );
}

export default App;