import React from 'react';
import { NavLink } from 'react-router-dom';

// Définir les éléments de menu
const menuItems = [
    { path: "/", label: "Accueil" },
    { path: "/agenda", label: "Agenda" },
    { path: "/foodanddrinks", label: "Food & Drinks" },
    { path: "/merchandising", label: "Merchandising" },
    { path: "/ticket", label: "Ticket", isButton: true }, // Indiquer que c'est un bouton
];

const Menu = ({ onClose, buttonStyles }) => {
    return (
        <div>
            {menuItems.map(({ path, label, isButton }) => (
                isButton ? (
                    <NavLink
                        key={path}
                        to={path}
                        className={buttonStyles} // Appliquer le style du bouton
                        onClick={onClose}
                    >
                        {label}
                    </NavLink>
                ) : (
                    <NavLink
                        key={path}
                        to={path}
                        className={({ isActive }) => (isActive ? 'activeLink' : '')} // Appliquer la classe active
                        onClick={onClose}
                    >
                        {label}
                    </NavLink>
                )
            ))}
        </div>
    );
};

export default Menu;
