import { lazy } from 'react';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';

// Configuration des routes
export const ROUTES_CONFIG = [
  {
    path: '/agenda',
    component: lazy(() => import('../pages/Agenda/AgendaPage')),
    title: 'Agenda',
    description: 'Découvrez tous nos événements - Concerts et soirées au Poney Club Toulouse'
  },
  {
    path: '/foodanddrinks',
    component: lazy(() => import('../pages/Food/FoodPage')),
    title: 'Food & Drinks',
    description: 'Découvrez notre carte de restauration et notre sélection de boissons'
  },
  {
    path: '/ticket',
    component: lazy(() => import('../pages/Ticket/TicketPage')),
    title: 'Billeterie',
    description: 'Réservez vos billets pour les événements au Poney Club Toulouse'
  },
  {
    path: '/agenda/:slug',
    component: lazy(() => import('../pages/SingleEvent/SingleEvent')),
    title: 'Événement',
    description: 'Détails de l\'événement au Poney Club Toulouse'
  },
  {
    path: '/artistes/:slug',
    component: lazy(() => import('../components/SingleArtiste')),
    title: 'Artiste',
    description: 'Découvrez l\'artiste et ses performances au Poney Club Toulouse'
  },
  {
    path: '/merchandising',
    component: lazy(() => import('../pages/Merch/MerchandisePage')),
    title: 'Boutique',
    description: 'Découvrez notre collection de merchandising officiel'
  }
];

// Configuration des sections de la page d'accueil
export const HOME_SECTIONS_SEO = {
  title: 'Accueil',
  description: 'Poney Club Toulouse - Le meilleur de la musique live à Toulouse. Concerts, événements et soirées exceptionnels.',
  keywords: 'club musique, concerts toulouse, soirées toulouse, live music, événements musicaux',
  sections: [
    { id: 'hero', title: 'À la une' },
    { id: 'events', title: 'Événements' },
    { id: 'artistes', title: 'Artistes' },
    { id: 'merch', title: 'Boutique' },
    { id: 'food', title: 'Food & Drinks' },
    { id: 'about', title: 'À propos' },
    { id: 'partenaires', title: 'Partenaires' }
  ]
};

// Création du router avec les future flags
export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {ROUTES_CONFIG.map(({ path, component: Component, title, description }) => (
        <Route
          key={path}
          path={path}
          element={<Component />}
          handle={{ title, description }}
        />
      ))}
      <Route
        path="/"
        element={lazy(() => import('../App'))}
        handle={{ 
          title: HOME_SECTIONS_SEO.title, 
          description: HOME_SECTIONS_SEO.description,
          keywords: HOME_SECTIONS_SEO.keywords
        }}
      />
    </>
  ),
  {
    future: {
      v7_startTransition: false,
      v7_relativeSplatPath: false,
    },
  }
);

export default router;