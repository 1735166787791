// src/components/OverlayImage.js
import React, { useRef, useEffect } from 'react';
import html2canvas from 'html2canvas';
import styles from './OverlayImage.module.scss';
import overlayImage from '../assets/images/poneyclubup.svg';

const OverlayImage = ({ baseImage, onImageGenerated }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      html2canvas(containerRef.current, {
        useCORS: true,
        allowTaint: true,
        scale: 2 // Augmente la qualité de l'image générée
      }).then(canvas => {
        const generatedImageUrl = canvas.toDataURL('image/png');
        onImageGenerated(generatedImageUrl);
      });
    }
  }, [baseImage, onImageGenerated]);

  return (
    <div ref={containerRef} className={styles.imageWithOverlay}>
      <div style={{ position: 'relative', width: '1200px', height: '630px' }}>
        <img 
          src={baseImage} 
          alt="Base" 
          style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
        />
        <img 
          src={overlayImage} 
          alt="Overlay" 
          className={styles.overlayImage}
        />
      </div>
    </div>
  );
};

export default OverlayImage;