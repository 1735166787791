import React from 'react';
import styles from './Footer.module.scss';
import Menu from './Menu'; // Importer le composant Menu
import { useQuery } from 'react-query';
import wordpressAPI from '../api/wordpress';

const Footer = () => {
  const { data: options } = useQuery('options', wordpressAPI.getSiteOptions);

  const menuItems = [
    { path: "/", label: "Accueil" },
    { path: "/agenda", label: "Agenda" },
    { path: "/foodanddrinks", label: "Food & Drinks" },
    { path: "/merchandising", label: "Merchandising" },
    { path: "/ticket", label: "Ticket" },
    { path: "/mentions-legales", label: "Mentions légales" },
    { path: "/politique-de-confidentialite", label: "Politique de confidentialité" },
    { path: "/contact", label: "Contact" },
  ];

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <p>&copy; {new Date().getFullYear()} Mon Application. Tous droits réservés.</p>
        <Menu items={menuItems} buttonStyles={styles.footerButton} /> {/* Utilisation du composant Menu */}
        {options?.a_propos?.reseaux_sociaux && (
          <div className={styles.socialLinks}>
            {options.a_propos.reseaux_sociaux.map((rs, index) => (
              <a
                href={rs.url}
                key={index}
                className={styles.socialLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>{rs.nom}</span>
              </a>
            ))}
          </div>
        )}
      </div>
    </footer>
  );
};

export default Footer;
