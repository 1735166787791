import { API_CONFIG } from '../config';

export class MediaService {
  constructor(api, cacheManager, baseURL) {
    this.api = api;
    this.cache = cacheManager;
    this.baseURL = baseURL;

    // Bind des méthodes
    this.fetchMedia = this.fetchMedia.bind(this);
    this.getFoods = this.getFoods.bind(this);
    this.getPartenaires = this.getPartenaires.bind(this);
    this.getMerchandising = this.getMerchandising.bind(this);
    this._processImageUrl = this._processImageUrl.bind(this);
  }

  async fetchMedia(mediaId) {
    if (!mediaId) return null;
    
    // Si c'est déjà une URL complète
    if (typeof mediaId === 'string' && mediaId.startsWith('http')) {
      return { url: mediaId };
    }

    const cacheKey = `wp_media_${mediaId}`;
    
    try {
      // Vérifier le cache en mémoire
      if (this.cache.mediaCache.has(mediaId)) {
        return {...this.cache.mediaCache.get(mediaId)};
      }

      // Vérifier le cache persistant
      const cachedMedia = this.cache.getCached(cacheKey);
      if (cachedMedia) {
        this.cache.mediaCache.set(mediaId, cachedMedia);
        return {...cachedMedia};
      }

      // Charger depuis l'API
      const response = await this.api.get(`/media/${mediaId}`);
      if (!response.data) return null;

      const mediaData = {
        id: mediaId,
        url: this._processImageUrl(response.data.source_url),
        alt: response.data.alt_text || '',
        title: response.data.title?.rendered || '',
        mime_type: response.data.mime_type || '',
        size: {
          width: response.data.media_details?.width || null,
          height: response.data.media_details?.height || null
        }
      };

      // Mettre en cache
      this.cache.mediaCache.set(mediaId, mediaData);
      this.cache.setCached(cacheKey, mediaData, API_CONFIG.DEFAULT_TTL);

      return {...mediaData};
    } catch (error) {
      console.error(`Error fetching media ${mediaId}:`, error);
      return null;
    }
  }

  async getFoods() {
    const cacheKey = 'wp_foods';

    try {
      // Vérifier le cache
      const cachedFoods = this.cache.getCached(cacheKey);
      if (cachedFoods) {
        return JSON.parse(JSON.stringify(cachedFoods));
      }

      // Charger depuis l'API
      const response = await this.api.get('/food', {
        params: {
          per_page: 100,
          _fields: 'id,title,content,acf,slug,status'
        }
      });

      if (!response.data) return [];

      const processedFoods = await Promise.all(
        response.data.map(async food => {
          try {
            const [logoUrl, imageUrl] = await Promise.all([
              food.acf?.logo ? this.fetchMedia(food.acf.logo) : null,
              food.acf?.image ? this.fetchMedia(food.acf.image) : null
            ]);

            return {
              id: food.id,
              title: food.title?.rendered || '',
              slug: food.slug,
              status: food.status,
              acf: {
                logo: logoUrl?.url || null,
                image: imageUrl?.url || null,
                menu: food.acf?.menu || null,
                type: food.acf?.type || '',
                description: food.content?.rendered || '',
                horaires: food.acf?.horaires || '',
                specialites: food.acf?.specialites || []
              }
            };
          } catch (error) {
            console.error(`Error processing food ${food.id}:`, error);
            return null;
          }
        })
      );

      const validFoods = processedFoods.filter(Boolean);
      this.cache.setCached(cacheKey, validFoods);

      return validFoods;
    } catch (error) {
      console.error('Error fetching foods:', error);
      return [];
    }
  }

  async getPartenaires() {
    const cacheKey = 'wp_partenaires';

    try {
      // Vérifier le cache
      const cachedPartenaires = this.cache.getCached(cacheKey);
      if (cachedPartenaires) {
        return JSON.parse(JSON.stringify(cachedPartenaires));
      }

      // Charger depuis l'API
      const response = await this.api.get('/partenaires', {
        params: {
          per_page: 100,
          _fields: 'id,title,acf,slug,status'
        }
      });

      const processedPartenaires = await Promise.all(
        response.data.map(async partenaire => {
          try {
            const logoMedia = await this.fetchMedia(partenaire.acf?.logo);

            return {
              id: partenaire.id,
              title: partenaire.title?.rendered || '',
              slug: partenaire.slug,
              status: partenaire.status,
              logo: logoMedia?.url || null,
              type: partenaire.acf?.type || '',
              url: partenaire.acf?.url || '',
              description: partenaire.acf?.description || ''
            };
          } catch (error) {
            console.error(`Error processing partenaire ${partenaire.id}:`, error);
            return null;
          }
        })
      );

      const validPartenaires = processedPartenaires.filter(p => p && p.logo);
      this.cache.setCached(cacheKey, validPartenaires);

      return validPartenaires;
    } catch (error) {
      console.error('Error fetching partenaires:', error);
      return [];
    }
  }

  async getMerchandising() {
    const cacheKey = 'wp_merchandising';

    try {
      const cachedMerchandising = this.cache.getCached(cacheKey);
      if (cachedMerchandising) {
        return JSON.parse(JSON.stringify(cachedMerchandising));
      }

      const response = await this.api.get('/merchandising', {
        params: {
          per_page: 100,
          _fields: 'id,title,content,acf,slug,status'
        }
      });

      const processedMerchandising = await Promise.all(
        response.data.map(async item => {
          try {
            const images = await Promise.all(
              (Array.isArray(item.acf?.photo) ? item.acf.photo : [])
                .map(photoId => this.fetchMedia(photoId))
            );

            const validImages = images
              .filter(Boolean)
              .map(image => image.url);

            return {
              id: item.id,
              title: item.title?.rendered || '',
              slug: item.slug,
              status: item.status,
              description: item.content?.rendered || '',
              type: item.acf?.type || '',
              price: item.acf?.prix || '',
              sizes: item.acf?.sizes || [],
              colors: item.acf?.colors || [],
              stock: item.acf?.stock || null,
              images: validImages,
              mainImage: validImages[0] || null,
              metadata: {
                seoDescription: item.acf?.seo_description || '',
                category: item.acf?.category || '',
                featured: item.acf?.featured || false
              }
            };
          } catch (error) {
            console.error(`Error processing merchandising item ${item.id}:`, error);
            return null;
          }
        })
      );

      const validMerchandising = processedMerchandising.filter(
        item => item && item.images.length > 0
      );

      this.cache.setCached(cacheKey, validMerchandising);
      return validMerchandising;

    } catch (error) {
      console.error('Error fetching merchandising:', error);
      return [];
    }
  }

  // Méthodes utilitaires
  _processImageUrl(url) {
    if (!url) return null;
    return url.startsWith('http') ? url : `${this.baseURL}/wp-content/uploads/${url}`;
  }

  // Méthodes de recherche et filtrage
  async searchMerchandising(searchTerm) {
    const allMerchandising = await this.getMerchandising();
    const normalizedSearch = searchTerm.toLowerCase();

    return allMerchandising.filter(item => {
      const searchableText = [
        item.title,
        item.description,
        item.type,
        item.metadata.category
      ].join(' ').toLowerCase();

      return searchableText.includes(normalizedSearch);
    });
  }

  async getFoodsByType(type) {
    const allFoods = await this.getFoods();
    return allFoods.filter(food => food.acf.type === type);
  }

  async getFeaturedMerchandising() {
    const allMerchandising = await this.getMerchandising();
    return allMerchandising.filter(item => item.metadata.featured);
  }

  clearCache() {
    this.cache.mediaCache.clear();
  }
}