import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ReactComponent as LogoPoney } from '../assets/images/logoPoney.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import styles from './Header.module.scss';
import buttonStyles from '../styles/Button.module.scss';
import { useQuery } from 'react-query';
import wordpressAPI from '../api/wordpress';

function Header() {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const location = useLocation();

    const { data: options } = useQuery('options', wordpressAPI.getSiteOptions);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(prev => !prev);
    };

    const closeMobileMenu = () => {
        setIsMobileMenuOpen(false);
    };

    useEffect(() => {
        closeMobileMenu(); // Ferme le menu mobile sur changement de route
    }, [location]);

    useEffect(() => {
        document.body.style.overflow = isMobileMenuOpen ? 'hidden' : 'visible';
        return () => {
            document.body.style.overflow = 'visible'; // Rétablir le style lors du démontage
        };
    }, [isMobileMenuOpen]);

    const getSocialIcon = (nom) => {
        switch (nom.toLowerCase()) {
            case 'facebook': return faFacebookF;
            case 'instagram': return faInstagram;
            default: return null;
        }
    };

    return (
        <header className={styles.header}>
            <div className={styles.container}>
                <div className={styles.logoContainer}>
                    <NavLink to="/" onClick={closeMobileMenu}>
                        <LogoPoney className={styles.logo} />
                    </NavLink>
                </div>
                <button
                    className={`${styles.mobileMenuButton} ${isMobileMenuOpen ? styles.open : ''}`}
                    onClick={toggleMobileMenu}
                    aria-label="Toggle menu"
                >
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
                <nav className={`${styles.nav} ${isMobileMenuOpen ? styles.navOpen : ''}`}>
                    <NavLink
                        to="/"
                        className={({ isActive }) => (isActive ? styles.activeLink : '')}
                        onClick={closeMobileMenu}
                    >
                        Accueil
                    </NavLink>
                    <NavLink
                        to="/agenda"
                        className={({ isActive }) => (isActive ? styles.activeLink : '')}
                        onClick={closeMobileMenu}
                    >
                        Agenda
                    </NavLink>
                    <NavLink
                        to="/foodanddrinks"
                        className={({ isActive }) => (isActive ? styles.activeLink : '')}
                        onClick={closeMobileMenu}
                    >
                        Food & Drinks
                    </NavLink>
                    <NavLink
                        to="/merchandising"
                        className={({ isActive }) => (isActive ? styles.activeLink : '')}
                        onClick={closeMobileMenu}
                    >
                        Merchandising
                    </NavLink>
                    <NavLink
                        to="/ticket"
                        className={({ isActive }) =>
                            `${buttonStyles.button} ${buttonStyles.buttonDark} ${isActive ? styles.active : ''}`
                        }
                        onClick={closeMobileMenu}
                    >
                        Ticket
                    </NavLink>

                    {options?.a_propos?.reseaux_sociaux && options.a_propos.reseaux_sociaux.length > 0 && (
                        <div className={styles.socialLinks}>
                            {options.a_propos.reseaux_sociaux.map((rs, index) => (
                                <a
                                    href={rs.url}
                                    key={index}
                                    className={styles.socialLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label={rs.nom} // Ajoute un label pour l'accessibilité
                                >
                                    <FontAwesomeIcon icon={getSocialIcon(rs.nom)} />
                                </a>
                            ))}
                        </div>
                    )}
                </nav>
            </div>
        </header>
    );
}

export default Header;
