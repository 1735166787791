import { API_CONFIG } from '../config';

export class ArtistesService {
  constructor(api, cacheManager) {
    this.api = api;
    this.cache = cacheManager;
    this.loadingArtistes = null;
    
    // Bind des méthodes
    this.loadAllArtistes = this.loadAllArtistes.bind(this);
    this.getBySlug = this.getBySlug.bind(this);
    this._processArtiste = this._processArtiste.bind(this);
    this._fetchArtisteBySlug = this._fetchArtisteBySlug.bind(this);
  }

  async loadAllArtistes() {
    // Si un chargement est déjà en cours, retourner la promesse existante
    if (this.loadingArtistes) return this.loadingArtistes;

    const cacheKey = 'wp_all_artistes';

    this.loadingArtistes = (async () => {
      try {
        // Vérifier le cache
        const cachedArtistes = this.cache.getCached(cacheKey);
        if (cachedArtistes?.length) {
          // Remplir le cache artiste par artiste
          cachedArtistes.forEach(artiste => {
            this.cache.artisteCache.set(artiste.slug, artiste);
          });
          return cachedArtistes;
        }

        // Charger depuis l'API
        const response = await this.api.get('/artistes', {
          params: {
            per_page: 100,
            _fields: 'id,title,slug,acf,content'
          }
        });

        if (!response.data) throw new Error('No artistes data received');

        const artistes = response.data.map(this._processArtiste);

        // Mettre en cache chaque artiste individuellement
        artistes.forEach(artiste => {
          this.cache.artisteCache.set(artiste.slug, artiste);
        });

        // Mettre en cache la liste complète
        this.cache.setCached(cacheKey, artistes);

        return artistes;
      } catch (error) {
        console.error('Error loading artistes:', error);
        return [];
      } finally {
        this.loadingArtistes = null;
      }
    })();

    return this.loadingArtistes;
  }

  async getBySlug(slug) {
    if (!slug) return null;

    try {
      // Vérifier d'abord le cache des artistes
      const cachedArtiste = this.cache.artisteCache.get(slug);
      if (cachedArtiste) {
        return { ...cachedArtiste }; // Retourner une copie
      }

      // Si l'artiste n'est pas en cache, essayer de charger tous les artistes
      await this.loadAllArtistes();
      
      // Vérifier à nouveau le cache
      const artisteFromCache = this.cache.artisteCache.get(slug);
      if (artisteFromCache) {
        return { ...artisteFromCache };
      }

      // Si toujours pas trouvé, faire une requête spécifique
      return await this._fetchArtisteBySlug(slug);
    } catch (error) {
      console.error(`Error fetching artist ${slug}:`, error);
      return null;
    }
  }

  async _fetchArtisteBySlug(slug) {
    try {
      const response = await this.api.get('/artistes', {
        params: {
          slug,
          _fields: 'id,title,slug,acf,content'
        }
      });

      if (!response.data?.length) return null;

      const artiste = this._processArtiste(response.data[0]);
      
      // Mettre en cache
      this.cache.artisteCache.set(slug, artiste);
      
      return { ...artiste };
    } catch (error) {
      console.error(`Error fetching single artist ${slug}:`, error);
      return null;
    }
  }

  _processArtiste(rawArtiste) {
    const acf = rawArtiste.acf || {};
    
    return {
      id: rawArtiste.id,
      title: rawArtiste.title?.rendered || '',
      slug: rawArtiste.slug,
      content: rawArtiste.content?.rendered || '',
      type_performance: acf.type_performance || '',
      genre_musical: acf.genre_musical || '',
      biographie: acf.biographie || '',
      reseaux_sociaux: this._processReseauxSociaux(acf.reseaux_sociaux),
      photo: acf.photo || null,
      soundcloudTrackId: acf.soundcloud_track_id || null,
      youtubeVideoId: acf.youtube_video_id || null,
      spotifyUrl: acf.spotify_url || null,
      // Ajouter d'autres champs personnalisés ici si nécessaire
    };
  }

  _processReseauxSociaux(reseaux = []) {
    if (!Array.isArray(reseaux)) return [];
    
    return reseaux.map(reseau => ({
      nom: reseau.nom || '',
      url: reseau.url || '',
    })).filter(reseau => reseau.nom && reseau.url);
  }

  // Méthodes utilitaires supplémentaires
  async getArtistesByType(type) {
    await this.loadAllArtistes();
    
    return Array.from(this.cache.artisteCache.values())
      .filter(artiste => artiste.type_performance === type)
      .map(artiste => ({ ...artiste }));
  }

  async searchArtistes(searchTerm) {
    await this.loadAllArtistes();
    
    const normalizedSearch = searchTerm.toLowerCase();
    
    return Array.from(this.cache.artisteCache.values())
      .filter(artiste => {
        const titre = artiste.title.toLowerCase();
        const genre = artiste.genre_musical.toLowerCase();
        return titre.includes(normalizedSearch) || genre.includes(normalizedSearch);
      })
      .map(artiste => ({ ...artiste }));
  }

  clearCache() {
    this.cache.artisteCache.clear();
    this.loadingArtistes = null;
  }
}