export const getFromCache = (key) => {
  const cached = localStorage.getItem(key);
  if (cached) {
    const { value, expiry } = JSON.parse(cached);
    if (expiry > Date.now()) {
      return value;
    }
    localStorage.removeItem(key);
  }
  return null;
};

export const setToCache = (key, value, ttl = 300000) => { // 5 minutes par défaut
  const item = {
    value: value,
    expiry: Date.now() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const clearCache = (key) => {
  localStorage.removeItem(key);
};
