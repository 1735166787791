// src/components/ScrollToTop.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    const scrollToTop = () => {
      const c = document.documentElement.scrollTop || document.body.scrollTop;
      if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        // Ajustez le nombre 8 pour modifier la vitesse (plus le nombre est grand, plus c'est rapide)
        window.scrollTo(0, c - c / 8);
      }
    };
    scrollToTop();
  }, [pathname]);

  return null;
}

export default ScrollToTop;