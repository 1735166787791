import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import homeAudio from '../../assets/sounds/home.mp3';
import styles from './FloatingAudioButton.module.scss';

function FloatingAudioButton({ isAudioEnabled, onAudioStatusChange, onVoluntaryActivation }) {
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [userActivated, setUserActivated] = useState(false);
    const location = useLocation();

    const fadeAudio = useCallback((audio, targetVolume, duration = 1000) => {
        const startVolume = audio.volume;
        const volumeDiff = targetVolume - startVolume;
        const startTime = performance.now();

        const fadeStep = (currentTime) => {
            const elapsedTime = currentTime - startTime;
            if (elapsedTime < duration) {
                const newVolume = startVolume + (volumeDiff * (elapsedTime / duration));
                audio.volume = Math.max(0, Math.min(1, newVolume));
                requestAnimationFrame(fadeStep);
            } else {
                audio.volume = targetVolume;
                if (targetVolume === 0) {
                    audio.pause();
                }
            }
        };

        requestAnimationFrame(fadeStep);
    }, []);

    const stopAudio = useCallback(() => {
        const audio = audioRef.current;
        if (audio) {
            fadeAudio(audio, 0);
            setIsPlaying(false);
            onAudioStatusChange(false);
        }
    }, [fadeAudio, onAudioStatusChange]);

    const playAudio = useCallback(() => {
        const audio = audioRef.current;
        if (audio) {
            audio.volume = 0;
            audio.play().catch(error => console.error("Erreur lors de la lecture de l'audio:", error));
            fadeAudio(audio, 1);
            setIsPlaying(true);
            onAudioStatusChange(true);
        }
    }, [fadeAudio, onAudioStatusChange]);

    useEffect(() => {
        const audio = audioRef.current;
        if (location.pathname.startsWith('/evenements/')) {
            stopAudio();
        } else if (userActivated && !isPlaying) {
            playAudio();
        }
    }, [location.pathname, userActivated, isPlaying, stopAudio, playAudio]);

    const toggleAudio = () => {
        if (isPlaying) {
            stopAudio();
            setUserActivated(false);
            onVoluntaryActivation(false);
        } else {
            playAudio();
            setUserActivated(true);
            onVoluntaryActivation(true);
        }
    };

    useEffect(() => {
        if (isAudioEnabled && !isPlaying && userActivated) {
            playAudio();
        } else if (!isAudioEnabled && isPlaying) {
            stopAudio();
        }
    }, [isAudioEnabled, isPlaying, userActivated, playAudio, stopAudio]);

    return (
        <div className={styles.floatingButton} onClick={toggleAudio}>
            {isPlaying ? '🔊' : '🔈'}
            <audio ref={audioRef} src={homeAudio} loop />
        </div>
    );
}

export default FloatingAudioButton;