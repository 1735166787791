import { API_CONFIG } from '../config';

export class EventsService {
  constructor(api, cacheManager, artistesService) {
    this.api = api;
    this.cache = cacheManager;
    this.artistesService = artistesService;
    
    // Bind des méthodes
    this.getAll = this.getAll.bind(this);
    this.getBySlug = this.getBySlug.bind(this);
    this.getUpcoming = this.getUpcoming.bind(this);
    this._processEvent = this._processEvent.bind(this);
    this._processArtistePrincipal = this._processArtistePrincipal.bind(this);
    this._processArtistesSupport = this._processArtistesSupport.bind(this);
  }

  async getAll() {
    const cacheKey = 'wp_evenements';
  
    try {
      // Vérifier le cache
      const cachedEvents = this.cache.getCached(cacheKey);
      if (cachedEvents) {
        return JSON.parse(JSON.stringify(cachedEvents));
      }
  
      // S'assurer que les artistes sont chargés
      await this.artistesService.loadAllArtistes();
  
      // Charger les événements
      const response = await this.api.get('/evenements', {
        params: {
          per_page: 100,
          _fields: 'id,title,slug,content,acf,date,status',

        }
      });
  
      if (!response.data) {
        throw new Error('Aucune donnée d\'événement reçue');
      }
  
      const processedEvents = await this._processEvents(response.data);
  
      // Mettre en cache
      this.cache.setCached(cacheKey, processedEvents);
  
      return JSON.parse(JSON.stringify(processedEvents));
    } catch (error) {
      if (error.response?.data?.details?.order?.message) {
        // Gérer spécifiquement l'erreur liée au paramètre 'order'
        console.error('Erreur lors de la récupération des événements :', error.response.data.details.order.message);
        throw new Error('Une erreur est survenue lors de la récupération des événements. Veuillez réessayer plus tard.');
      } else {
        console.error('Erreur lors de la récupération des événements :', error);
        throw error;
      }
    }
  }

  async getBySlug(slug) {
    if (!slug) throw new Error('Event slug is required');
    
    const cacheKey = `wp_evenement_${slug}`;
    
    try {
      // Vérifier le cache
      const cachedEvent = this.cache.getCached(cacheKey);
      if (cachedEvent) {
        return JSON.parse(JSON.stringify(cachedEvent));
      }

      // S'assurer que les artistes sont chargés
      await this.artistesService.loadAllArtistes();

      // Charger l'événement
      const response = await this.api.get('/evenements', {
        params: {
          slug,
          _fields: 'id,title,slug,content,acf,date,status'
        }
      });

      if (!response.data?.length) {
        throw new Error(`Event ${slug} not found`);
      }

      const processedEvent = await this._processEvent(response.data[0]);
      
      // Mettre en cache
      this.cache.setCached(cacheKey, processedEvent);
      
      return JSON.parse(JSON.stringify(processedEvent));
    } catch (error) {
      console.error(`Error fetching event ${slug}:`, error);
      throw error;
    }
  }

  async getUpcoming() {
    try {
      const allEvents = await this.getAll();
      const now = new Date();

      return allEvents.filter(event => {
        const eventDate = new Date(event.date);
        return eventDate >= now;
      }).sort((a, b) => new Date(a.date) - new Date(b.date));
    } catch (error) {
      console.error('Error fetching upcoming events:', error);
      return [];
    }
  }

  async _processEvents(events) {
    try {
      const processedEvents = await Promise.all(
        events.map(event => this._processEvent(event))
      );

      return processedEvents.filter(Boolean);
    } catch (error) {
      console.error('Error processing events:', error);
      return [];
    }
  }

  async _processEvent(event) {
    try {
      if (!event?.acf?.artiste?.[0]) {
        console.warn(`Event ${event.id} has no main artist`);
        return null;
      }

      const artistePrincipal = await this._processArtistePrincipal(event.acf.artiste[0]);
      if (!artistePrincipal) return null;

      const artistesSupport = await this._processArtistesSupport(event.acf?.support?.[0]?.artiste);

      return {
        id: event.id,
        title: event.title?.rendered || '',
        slug: event.slug,
        date: event.acf?.date || null,
        content: event.content?.rendered || '',
        status: event.status || 'publish',
        artistePrincipal,
        artistesSupport,
        pricing: this._processPricing(event.acf?.pricing),
        venue: this._processVenue(event.acf?.venue),
        metadata: {
          seoTitle: event.acf?.seo_title || '',
          seoDescription: event.acf?.seo_description || '',
          shareImage: event.acf?.share_image || null
        }
      };
    } catch (error) {
      console.error(`Error processing event ${event.id}:`, error);
      return null;
    }
  }

  async _processArtistePrincipal(artiste) {
    if (!artiste?.post_name) return null;

    try {
      const artisteDetails = await this.artistesService.getBySlug(artiste.post_name);
      if (!artisteDetails) return null;

      return {
        id: artiste.ID,
        name: artiste.post_title,
        slug: artisteDetails.slug,
        photo: artisteDetails.photo,
        type_performance: artisteDetails.type_performance,
        genre_musical: artisteDetails.genre_musical,
        soundcloudTrackId: artisteDetails.soundcloudTrackId,
        youtubeVideoId: artisteDetails.youtubeVideoId,
        spotifyUrl: artisteDetails.spotifyUrl,
        biographie: artisteDetails.biographie,
        reseaux_sociaux: artisteDetails.reseaux_sociaux
      };
    } catch (error) {
      console.error(`Error processing main artist ${artiste.post_name}:`, error);
      return null;
    }
  }

  async _processArtistesSupport(artistes = []) {
    if (!Array.isArray(artistes)) return [];

    try {
      const supportArtistes = await Promise.all(
        artistes.map(async (artiste) => {
          if (!artiste?.post_name) return null;

          const supportDetails = await this.artistesService.getBySlug(artiste.post_name);
          if (!supportDetails) return null;

          return {
            id: artiste.ID,
            name: artiste.post_title,
            slug: artiste.post_name,
            photo: supportDetails.photo,
            type_performance: supportDetails.type_performance,
            genre_musical: supportDetails.genre_musical
          };
        })
      );

      return supportArtistes.filter(Boolean);
    } catch (error) {
      console.error('Error processing support artists:', error);
      return [];
    }
  }

  _processPricing(pricing) {
    if (!pricing) return null;

    return {
      early: pricing.early_bird || null,
      regular: pricing.regular || null,
      door: pricing.door || null,
      vip: pricing.vip || null,
      notes: pricing.notes || ''
    };
  }

  _processVenue(venue) {
    if (!venue) return null;

    return {
      name: venue.name || '',
      address: venue.address || '',
      capacity: venue.capacity || null,
      notes: venue.notes || ''
    };
  }

  // Méthodes utilitaires
  async searchEvents(searchTerm) {
    const allEvents = await this.getAll();
    const normalizedSearch = searchTerm.toLowerCase();

    return allEvents.filter(event => {
      const searchableText = [
        event.title,
        event.artistePrincipal?.name,
        event.artistePrincipal?.type_performance,
        ...event.artistesSupport.map(a => a.name)
      ].join(' ').toLowerCase();

      return searchableText.includes(normalizedSearch);
    });
  }

  async getEventsByArtist(artistSlug) {
    const allEvents = await this.getAll();

    return allEvents.filter(event => {
      return event.artistePrincipal?.slug === artistSlug ||
             event.artistesSupport.some(a => a.slug === artistSlug);
    });
  }

  async getEventsByMonth(year, month) {
    const allEvents = await this.getAll();
    
    return allEvents.filter(event => {
      const eventDate = new Date(event.date);
      return eventDate.getFullYear() === year &&
             eventDate.getMonth() === month - 1;
    });
  }

  clearCache() {
    this.cache.eventCache.clear();
  }
}