import { getFromCache, setToCache } from '../utils/cache';

export class CacheManager {
  static DEFAULT_TTL = 300000;  // 5 minutes
  static LONG_TTL = 900000;     // 15 minutes
  static MAX_MEMORY_ITEMS = 1000; // Limite du cache en mémoire

  constructor() {
    // Caches en mémoire séparés pour différents types de données
    this.mediaCache = new Map();
    this.artisteCache = new Map();
    this.eventCache = new Map();
    this.generalCache = new Map();

    // Statistiques de cache
    this._stats = {
      hits: 0,
      misses: 0,
      memorySize: 0
    };

    // Bind des méthodes
    this.getCached = this.getCached.bind(this);
    this.setCached = this.setCached.bind(this);
    this.getFromMemoryCache = this.getFromMemoryCache.bind(this);
    this.setToMemoryCache = this.setToMemoryCache.bind(this);
    this.clearCache = this.clearCache.bind(this);
  }

  /**
   * Récupère une donnée du cache
   * @param {string} key - Clé de cache
   * @param {string} type - Type de cache ('media', 'artiste', 'event', 'general')
   * @returns {any|null} Donnée mise en cache ou null
   */
  getCached(key, type = 'general') {
    try {
      // Vérifier d'abord le cache en mémoire
      const memoryResult = this.getFromMemoryCache(key, type);
      if (memoryResult) {
        this._stats.hits++;
        return this._deepClone(memoryResult);
      }

      // Puis vérifier le cache persistant
      const persistentResult = getFromCache(key);
      if (persistentResult) {
        // Mettre en cache mémoire pour les prochains accès
        this.setToMemoryCache(key, persistentResult, type);
        this._stats.hits++;
        return this._deepClone(persistentResult);
      }

      this._stats.misses++;
      return null;
    } catch (error) {
      console.error('Cache get error:', error);
      return null;
    }
  }

  /**
   * Met en cache une donnée
   * @param {string} key - Clé de cache
   * @param {any} data - Données à mettre en cache
   * @param {number} ttl - Durée de vie en ms
   * @param {string} type - Type de cache
   */
  setCached(key, data, ttl = CacheManager.DEFAULT_TTL, type = 'general') {
    try {
      // Mettre en cache mémoire
      this.setToMemoryCache(key, data, type);
      
      // Mettre en cache persistant
      setToCache(key, data, ttl);
    } catch (error) {
      console.error('Cache set error:', error);
    }
  }

  /**
   * Récupère depuis le cache mémoire
   */
  getFromMemoryCache(key, type) {
    const cache = this._getCache(type);
    return cache.get(key) || null;
  }

  /**
   * Met en cache mémoire avec gestion de la taille
   */
  setToMemoryCache(key, data, type) {
    const cache = this._getCache(type);
    
    // Vérifier la taille du cache
    if (cache.size >= CacheManager.MAX_MEMORY_ITEMS) {
      // Supprimer les entrées les plus anciennes
      const keysToDelete = Array.from(cache.keys()).slice(0, Math.floor(CacheManager.MAX_MEMORY_ITEMS * 0.2));
      keysToDelete.forEach(k => cache.delete(k));
    }

    cache.set(key, this._deepClone(data));
    this._stats.memorySize = this._calculateMemorySize();
  }

  /**
   * Invalide une entrée du cache
   */
  invalidate(key, type = 'general') {
    try {
      const cache = this._getCache(type);
      cache.delete(key);
      // Invalider aussi le cache persistant
      setToCache(key, null, 0);
    } catch (error) {
      console.error('Cache invalidation error:', error);
    }
  }

  /**
   * Invalide toutes les entrées correspondant à un motif
   */
  invalidatePattern(pattern, type = 'general') {
    try {
      const cache = this._getCache(type);
      const regex = new RegExp(pattern);
      
      for (const [key] of cache) {
        if (regex.test(key)) {
          this.invalidate(key, type);
        }
      }
    } catch (error) {
      console.error('Pattern invalidation error:', error);
    }
  }

  /**
   * Vide le cache
   */
  clearCache(type) {
    if (type) {
      const cache = this._getCache(type);
      cache.clear();
    } else {
      this.mediaCache.clear();
      this.artisteCache.clear();
      this.eventCache.clear();
      this.generalCache.clear();
    }
    this._stats.memorySize = this._calculateMemorySize();
  }

  /**
   * Récupère les statistiques du cache
   */
  getStats() {
    return {
      ...this._stats,
      mediaCacheSize: this.mediaCache.size,
      artisteCacheSize: this.artisteCache.size,
      eventCacheSize: this.eventCache.size,
      generalCacheSize: this.generalCache.size,
      hitRatio: this._stats.hits / (this._stats.hits + this._stats.misses)
    };
  }

  // Méthodes privées
  _getCache(type) {
    switch (type) {
      case 'media':
        return this.mediaCache;
      case 'artiste':
        return this.artisteCache;
      case 'event':
        return this.eventCache;
      default:
        return this.generalCache;
    }
  }

  _deepClone(data) {
    try {
      return JSON.parse(JSON.stringify(data));
    } catch {
      return data;
    }
  }

  _calculateMemorySize() {
    return this.mediaCache.size +
           this.artisteCache.size +
           this.eventCache.size +
           this.generalCache.size;
  }

  // Méthodes utilitaires
  async fetchWithCache(fetcher, key, ttl = CacheManager.DEFAULT_TTL, type = 'general') {
    try {
      const cachedData = this.getCached(key, type);
      if (cachedData) return cachedData;

      const data = await fetcher();
      this.setCached(key, data, ttl, type);
      return this._deepClone(data);
    } catch (error) {
      console.error(`Cache fetch error for ${key}:`, error);
      throw error;
    }
  }

  hasExpired(key) {
    try {
      const data = getFromCache(key, true);
      return !data;
    } catch {
      return true;
    }
  }

  async prefetch(keys, fetcher, ttl = CacheManager.DEFAULT_TTL, type = 'general') {
    return Promise.all(
      keys.map(async key => {
        if (!this.getCached(key, type)) {
          try {
            const data = await fetcher(key);
            this.setCached(key, data, ttl, type);
          } catch (error) {
            console.error(`Prefetch error for ${key}:`, error);
          }
        }
      })
    );
  }
}

export const cacheManager = new CacheManager();