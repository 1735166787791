// src/components/SEO.js
import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import wordpressAPI from '../api/wordpress';
import OverlayImage from './OverlayImage';

function SEO({ title, description, image }) {
  const location = useLocation();
  const [overlayImageUrl, setOverlayImageUrl] = useState(null);
  const isHomePage = location.pathname === '/';

  const { data: seoOptions, isError, error } = useQuery(
    'seoOptions', 
    wordpressAPI.getSiteOptions,
    {
      retry: 3,
      onError: (error) => {
        console.error("Erreur lors de la récupération des options SEO:", error);
      }
    }
  );

  const siteName = seoOptions?.seo?.nom_de_lapp || 'Poney Club Toulouse';
  const defaultDescription = seoOptions?.seo?.description || '';
  const defaultImage = seoOptions?.seo?.image_seo || '';

  // Pour le titre, on utilise une logique simple :
  // - Sur la home page : juste le siteName
  // - Sur les autres pages : juste le titre de la page s'il existe, sinon le siteName
  const finalTitle = isHomePage ? siteName : (title || siteName);
  const finalDescription = description || defaultDescription;
  const finalImage = overlayImageUrl || image || defaultImage;

  useEffect(() => {
    if (image) {
      const generateOverlayImage = async () => {
        try {
          const generatedImageUrl = await new Promise((resolve) => {
            const onImageGenerated = (url) => {
              resolve(url);
            };
            const div = document.createElement('div');
            div.style.position = 'absolute';
            div.style.left = '-9999px';
            document.body.appendChild(div);
            const root = createRoot(div);
            root.render(
              <OverlayImage
                baseImage={image}
                onImageGenerated={onImageGenerated}
              />
            );
          });
          setOverlayImageUrl(generatedImageUrl);
        } catch (error) {
          console.error("Erreur lors de la génération de l'image de superposition:", error);
        }
      };
      generateOverlayImage();
    }
  }, [image]);

  if (isError) {
    console.error("Erreur lors du chargement des options SEO:", error);
  }

  return (
    <Helmet>
      <title>{finalTitle}</title>
      <meta name="description" content={finalDescription} />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:title" content={finalTitle} />
      <meta property="og:description" content={finalDescription} />
      <meta property="og:image" content={finalImage} />
      <meta property="og:site_name" content={siteName} />
      
      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={window.location.href} />
      <meta property="twitter:title" content={finalTitle} />
      <meta property="twitter:description" content={finalDescription} />
      <meta property="twitter:image" content={finalImage} />
    </Helmet>
  );
}

export default SEO;